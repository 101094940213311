import { type BaseBoxProps, Box } from '@tokenterminal/ui/Box'
import {
  baseCss,
  dividerCss,
  secondaryDividerCss,
  verticalDividerCss,
} from './Divider.css'

type DividerProps = {
  vertical?: boolean
  variant?: 'primary' | 'secondary'
} & Omit<BaseBoxProps, 'children'>

export function Divider({
  vertical = false,
  variant = 'primary',
  ...props
}: DividerProps) {
  return (
    <Box
      {...props}
      aria-hidden="true"
      className={[
        baseCss,
        {
          [dividerCss]: !vertical,
          [verticalDividerCss]: vertical,
          [secondaryDividerCss]: variant === 'secondary',
        },
      ]}
    />
  )
}
